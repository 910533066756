import currencies from "./currencies"
import * as v from "valibot"
import { createAssertFn, createValidateFn } from "@/utils/validator"
import { CartItem, ConversionItem, Equals, Expect, Extends, isPageType, PushedCustomer } from "@/types"
import { Data } from "@/core/tagging/types"

function nullish<T extends v.BaseSchema>(schema: T) {
  return v.optional(v.nullish(schema))
}

const stringToNumber = (v: unknown) => (typeof v === "string" && !Number.isNaN(+v) ? Number(v) : v)

const cartItemSchema = v.object({
  name: v.string(),
  price_currency_code: v.string([v.custom(val => currencies.includes(val), "Invalid currency code")]),
  product_id: v.string(),
  quantity: v.number([v.minValue(0)]),
  sku_id: v.optional(v.string()),
  unit_price: v.coerce(v.number(), stringToNumber)
})

const orderItemSchema = v.object({
  name: v.string(),
  price_currency_code: v.string([v.custom(val => currencies.includes(val), "Invalid currency code")]),
  product_id: v.string(),
  quantity: v.optional(v.number([v.minValue(0)])),
  sku_id: v.optional(v.string()),
  unit_price: v.optional(v.coerce(v.number(), stringToNumber))
})

const customerSchema = v.object({
  first_name: nullish(v.string([v.minLength(1)])),
  last_name: nullish(v.string([v.minLength(1)])),
  email: nullish(v.string([v.email()])),
  customer_reference: nullish(v.string([v.minLength(1)])),
  newsletter: nullish(v.union([v.literal("true"), v.literal("false"), v.boolean()]))
})

const DataSchema = v.object({
  customer: nullish(customerSchema),
  cart: nullish(
    v.object({
      // support for empty cart definitions
      items: v.optional(v.array(cartItemSchema))
    })
  ),
  order: nullish(
    v.object({
      info: v.optional(
        v.object({
          order_number: v.string()
        })
      ),
      items: v.array(orderItemSchema)
    })
  ),
  searchTerms: nullish(v.array(v.string())),
  categories: nullish(v.array(v.string())),
  tags: nullish(v.array(v.string())),
  elements: nullish(v.array(v.string())),
  pageType: nullish(v.string([v.custom(isPageType, "Invalid page type")]))
})

export const assertValid = createAssertFn(DataSchema)

export const validate = createValidateFn(DataSchema)

export type tests = [
  // CartItem is equal to schema input
  Expect<Equals<CartItem, v.Input<typeof cartItemSchema>>>,
  // ConversionItem is equal to schema input
  Expect<Equals<ConversionItem, v.Input<typeof orderItemSchema>>>,
  // PushedCustomer is assignable to schema input
  Expect<Extends<PushedCustomer, v.Input<typeof customerSchema>>>,
  // Data is assignable to schema input
  Expect<Extends<Data, v.Input<typeof DataSchema>>>
]
