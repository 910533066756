import page from "@/core/page"
import text from "@/metadata/utils/text"
import updateRating from "./rating"
import { PushedProduct } from "@/types"

function getContent(el: HTMLElement) {
  return (el.getAttribute("content") || text(el)).trim()
}

/**
 * Extracts the value from the element's attributes or the element's value
 * itself
 *
 * @param {string} property the name of the item-property i.e. itemprop selector
 * @param {HTMLElement} root the root element from which to select embedded microdata
 */
function getValue(property: string, root: HTMLElement) {
  const nodes = Array.from(root.querySelectorAll<HTMLElement>(`[itemprop="${property}"]`))
  return nodes.map(getContent)[0]
}

/**
 * Parses the embedded microdata in the page and updates the ratings and
 * reviews of the product.
 *
 * @param {Product} object the product object to be updated
 * @returns a boolean indicating whether the object was updated or not
 */
export default function parseAggregateRatings(object: PushedProduct) {
  const rating = page.select("[itemprop='aggregateRating']")

  if (rating) {
    const bestRating = getValue("bestRating", rating)
    const ratingValue = getValue("ratingValue", rating)
    const reviewCount = getValue("reviewCount", rating)
    const ratingCount = getValue("ratingCount", rating)

    updateRating(object, bestRating, ratingValue, reviewCount, ratingCount)
    return true
  }

  return false
}
