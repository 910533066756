/* eslint-disable no-use-before-define */
import axios, { AxiosResponse } from "axios"
import settings from "@/core/settings"
import { SearchAnalyticsOptions, SearchTrackOptions, recordSearch } from "./analytics"
import getSearchSessionParams from "./sessionParams"
import logger from "@/core/logger"
import { buildSearchQuery } from "./graphql"
import { SearchQuery, SearchResult } from "./types"
import { getVariations, setVisitId, storeVariations } from "./variations"
import { Maybe } from "@/types"
import { isAxiosError } from "@/utils/axios/error"
import { reportSearchClick } from "@/search/analytics"
import { initSessionParams } from "@/search/sessionParams"
import bus from "@/core/api/bus"
import measurePerformance from "@/core/measurePerformance"

type SearchOptions = {
  redirect: boolean
  track: SearchTrackOptions
} & SearchAnalyticsOptions

type SearchResponse = {
  data?: {
    search: SearchResult
  }
  errors?: {
    message: string
    key?: string
  }[]
}

export function initSearch() {
  if (settings.searchEnabled) {
    bus.on("prerequest", reportSearchClick)
    bus.on("taggingsent", response => setVisitId(response.visit))
    initSessionParams()
  }
}

/**
 * Search function
 */
export default async function search(query: SearchQuery, options?: SearchOptions): Promise<SearchResult> {
  const { redirect = false, track, isKeyword } = options || {}
  const validateError = validateQuery(query)
  if (validateError) {
    return Promise.reject(validateError)
  }

  const sessionParams = await getSearchSessionParams()

  const body = JSON.stringify({
    query: buildSearchQuery(query),
    variables: {
      accountId: settings.account,
      query: query.query,
      segments: query.segments,
      products: query.products
        ? {
            ...query.products,
            fields: undefined
          }
        : undefined,
      keywords: query.keywords
        ? {
            ...query.keywords,
            facets: undefined,
            fields: undefined
          }
        : undefined,
      sessionParams,
      rules: query.rules,
      abTests: getVariations() ?? []
    }
  })

  const response = await handleSearchResponse(
    measurePerformance("nosto.search", () =>
      axios.post(getSearchUrl(), body, {
        headers: {
          "Content-Type": "text/plain"
        }
      })
    )
  )

  if (track) {
    recordSearch(track, query, response, {
      isKeyword
    })
  }

  if (response.redirect && redirect) {
    // eslint-disable-next-line no-restricted-globals
    window.location.href = response.redirect
    // TODO this short circuiting should be encoded better in the type
    return new Promise<SearchResult>(() => {})
  }

  if (response.abTests) {
    storeVariations(response.abTests)
  }

  return response
}

async function handleSearchResponse(response: Promise<AxiosResponse<SearchResponse>>) {
  try {
    const axiosResponse = (await response).data
    const errors = Array.isArray(axiosResponse.errors) ? axiosResponse.errors.map(e => e.message) : []
    if (axiosResponse.data?.search) {
      if (errors.length) {
        logger.warn(`Search has warnings: ${errors.join(", ")}`)
      }
      return axiosResponse.data.search
    }
    if (errors.length) {
      throw new Error(`Search failed: ${errors.join(", ")}`)
    }
    throw new Error(`Search failed with unknown error`)
  } catch (err) {
    if (isAxiosError<SearchResponse>(err)) {
      const errors = err.response?.data?.errors
      if (errors) {
        const errorMessages = Array.isArray(errors) ? errors.map(e => e.message) : []
        if (errorMessages.length) {
          throw new Error(`Search failed: ${errorMessages.join(", ")}`)
        }
      }
      throw new Error(`Search failed with error: ${err.message}`)
    }
    if (err instanceof Error) {
      throw err
    }
    throw new Error("Search failed with error:" + err)
  }
}

function validateQuery(query: SearchQuery): Maybe<Error> {
  if (!query) {
    return new Error("query is required")
  }
  if (query.products && query.products.fields && Array.isArray(query.products.fields)) {
    // eslint-disable-next-line consistent-return
    const err = query.products.fields.find(value => !value.split(".").every(v => v.match(/^[a-zA-Z]+[0-9]?$/)))
    if (err) {
      return new Error(`Invalid search field: ${err}`)
    }
  }
  return undefined
}

function getSearchUrl(): string {
  if (settings.searchApiUrl && settings.searchApiUrl.endsWith("/api/")) {
    return `${settings.searchApiUrl.slice(0, -5)}/v1/graphql`
  }
  return settings.searchApiUrl!
}
