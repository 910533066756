import axios from "axios"
import settings from "@/core/settings"
import getCustomerId from "@/core/customerId"
import logger from "@/core/logger"
import { findCustomer } from "@/core/tagging"
import { putAttribution } from "@/core/parameterlessAttribution"
import { ABTest, SearchProduct, SearchQuery, SearchResult } from "./types"
import { sessionStore } from "@/core/store"
import {
  SearchEventMetadata,
  SearchImpression,
  SearchClick,
  CategoryClick,
  CategoryImpression,
  CategoryEventMetadata,
  AnalyticEventProperties,
  maybe,
  Maybe
} from "@/types"
import { getVariations } from "./variations"

export type SearchTrackOptions = "serp" | "autocomplete" | "category"

type Type = "search" | "category"

type Endpoint = "impression" | "click"

type MetadataMapping = {
  search: SearchEventMetadata
  category: CategoryEventMetadata
}

export type SearchAnalyticsOptions = {
  isKeyword?: boolean
}

const metadataCache = {
  key: "nosto:search:analyticsMetadata" as const,
  get<T extends Type>(type: T) {
    return sessionStore.getAsJson<MetadataMapping[T]>(`${this.key}/${type}`)
  },
  set<T extends Type>(type: T, data: MetadataMapping[T]) {
    sessionStore.setAsJson(`${this.key}/${type}`, data)
  },
  reset(type: Type) {
    sessionStore.remove(`${this.key}/${type}`)
  }
}

const organicQueries = {
  key: "nosto:search:organicQueries" as const,
  get(): string[] {
    return sessionStore.getAsJson(this.key) || []
  },
  push(query: string) {
    sessionStore.setAsJson(this.key, [...this.get(), query].slice(-100))
  }
}

function checkSearchMetadata(endpoint: Endpoint, metadata: SearchEventMetadata) {
  if (!metadata.resultId) {
    const resultIdKey = metadata?.isAutoComplete ? "nosto:search:resultId:autoComplete" : "nosto:search:resultId:serp"
    if (endpoint == "impression") {
      const resultId = uuidv4()
      metadata.resultId = resultId
      sessionStore.set(resultIdKey, resultId)
    } else if (endpoint == "click") {
      // XXX is the session store guaranteed to have the result id?
      metadata.resultId = sessionStore.get(resultIdKey)!
    }
  }

  return {
    query: metadata?.query ?? "",
    resultId: metadata.resultId ?? uuidv4(),
    isOrganic: metadata?.isOrganic ?? true,
    isAutoCorrect: metadata?.isAutoCorrect ?? false,
    isAutoComplete: metadata?.isAutoComplete ?? false,
    isSorted: metadata?.isSorted ?? false,
    isKeyword: metadata?.isKeyword ?? false,
    hasResults: metadata?.hasResults ?? true,
    isRefined: metadata?.isRefined ?? false,
    refinedQuery: metadata?.refinedQuery ?? ""
  }
}

async function reportAnalytics(type: "search", endpoint: "impression", data: SearchImpression): Promise<unknown>
async function reportAnalytics(type: "search", endpoint: "click", data: SearchClick): Promise<unknown>
async function reportAnalytics(type: "category", endpoint: "impression", data: CategoryImpression): Promise<unknown>
async function reportAnalytics(type: "category", endpoint: "click", data: CategoryClick): Promise<unknown>

async function reportAnalytics(type: Type, endpoint: Endpoint, data: object) {
  const customerData = findCustomer()

  try {
    const customerId = await getCustomerId()
    if (!customerId) {
      logger.warn("Skipping analytics event, no customer id defined")
      return
    }
    await axios.post(`${settings.server}/analytics/${type}/${endpoint}`, data, {
      params: {
        merchant: settings.account,
        c: customerId,
        customerReference: customerData?.customer_reference || undefined
      }
    })
  } catch (e) {
    logger.warn(`Failed to send ${type} ${endpoint} analytics`, e)
  }
}

function uuidv4() {
  // @ts-expect-error TS(2365) FIXME: Operator '+' cannot be applied to types 'number[]'... Remove this comment to see the full error message
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
  )
}

const searchClickKey = "nosto:search:clickStorage"
const categoryClickKey = "nosto:category:clickStorage"

export async function reportSearchClick() {
  const searchClickData = sessionStore.getAsJson<SearchClick>(searchClickKey)
  if (searchClickData) {
    try {
      searchClickData.metadata = checkSearchMetadata("click", searchClickData.metadata)
      sessionStore.remove(searchClickKey)
      await reportAnalytics("search", "click", searchClickData)
    } catch (e) {
      logger.error("Could not report search click", e)
    }
  }

  const categoryClickData = sessionStore.getAsJson<CategoryClick>(categoryClickKey)
  if (categoryClickData) {
    try {
      sessionStore.remove(categoryClickKey)
      await reportAnalytics("category", "click", categoryClickData)
    } catch (e) {
      logger.error("Could not report category click", e)
    }
  }
}

export function storeSearchClick(
  productId: string = "",
  metadata: SearchEventMetadata,
  productUrl: string,
  properties: Maybe<AnalyticEventProperties>
) {
  metadata = checkSearchMetadata("click", metadata)
  sessionStore.setAsJson<SearchClick>(searchClickKey, { productId, metadata, properties })
  // add VP ref via parameterlessAttribution
  if (productUrl && metadata.resultId) {
    putAttribution(productUrl, { ref: metadata.resultId })
  }
}

export async function reportSearchImpression(
  productIds: string[] = [],
  metadata: SearchEventMetadata,
  page: number = 1,
  properties: Maybe<AnalyticEventProperties>
) {
  await reportAnalytics("search", "impression", {
    productIds,
    metadata: checkSearchMetadata("impression", metadata),
    page,
    properties
  })
}

const autoCompleteState = {
  lastQuery: maybe<string>(),
  lastResultId: maybe<string>(),
  resultIdForEmptyQuery: uuidv4()
}
/**
 * Get a unique result id based on the query and the search type
 */

export function getResultId(type: SearchTrackOptions, query: string) {
  if (type === "autocomplete") {
    if (!query) {
      return autoCompleteState.resultIdForEmptyQuery
    }

    if (
      autoCompleteState.lastQuery &&
      (query.startsWith(autoCompleteState.lastQuery) || autoCompleteState.lastQuery.startsWith(query))
    ) {
      return autoCompleteState.lastResultId
    }

    autoCompleteState.lastQuery = query
    autoCompleteState.lastResultId = uuidv4()
    return autoCompleteState.lastResultId
  }

  return uuidv4()
}

/**
 * Record search event, should be send on any search
 */
export function recordSearch(
  type: SearchTrackOptions,
  query: SearchQuery,
  response: SearchResult,
  options?: SearchAnalyticsOptions
) {
  if (!type || !["serp", "autocomplete", "category"].includes(type)) {
    throw new Error(`Invalid search track option: ${type}`)
  }

  const { isKeyword = false } = options || {}

  const properties = getEventProperties(response.abTests ?? [])

  if (response && response.products && query) {
    const productIds = (response.products.hits || []).map(hit => hit.productId).filter(Boolean)
    const from = response.products.from ? response.products.from : 0
    const page = response.products.size ? from / response.products.size + 1 : 0

    if (type === "category") {
      metadataCache.reset("category")
      const { categoryPath, categoryId } = response.products
      const metadata = categoryPath || categoryId ? { category: categoryPath!, categoryId } : undefined

      if (metadata) {
        metadataCache.set("category", metadata)
        void reportAnalytics("category", "impression", { productIds, metadata, page, properties })
      }

      return
    }

    metadataCache.reset("search")
    const resultId = getResultId(type, query.query || "")
    const previousQuery = type === "serp" ? organicQueries.get().slice(-2, -1)[0] : ""
    const isRefined = type === "serp" && !!previousQuery && previousQuery !== query

    const metadata: SearchEventMetadata = {
      query: query.query || "",
      resultId,
      isAutoCorrect: !!response.products.fuzzy,
      isAutoComplete: type === "autocomplete",
      isKeyword,
      isSorted: !!(query.products && query.products.sort),
      isOrganic: type === "serp" && organicQueries.get().includes(query.query || ""),
      isRefined,
      ...(isRefined && { refinedQuery: previousQuery || "" }),
      hasResults:
        !!response?.products?.total ||
        !!response.redirect ||
        (Array.isArray(query?.products?.filter) ? !!query.products!.filter.length : !!query?.products?.filter)
    }

    metadataCache.set("search", metadata)

    void reportSearchImpression(productIds, metadata, page, properties)
  }
}

/**
 * Record search submit event (e.g. search form submit). Required to track organic searches.
 */
export function recordSearchSubmit(query: string) {
  if (query) {
    organicQueries.push(query)
  }
}

function getEventProperties(variations: ABTest[]): Maybe<AnalyticEventProperties> {
  const abTestAttribution = variations.reduce<Record<string, string>>((acc, test) => {
    acc[test.id] = test.activeVariation.id
    return acc
  }, {})
  return Object.keys(abTestAttribution).length ? { abTestAttribution } : undefined
}

/**
 * Record search click event
 */
export function recordSearchClick(type: SearchTrackOptions, hit: SearchProduct) {
  if (!type || !["serp", "autocomplete", "category"].includes(type)) {
    throw new Error(`Invalid search click track option: ${type}`)
  }

  const properties = getEventProperties(getVariations())

  if (type === "category") {
    const metadata = metadataCache.get("category")
    if (metadata?.category || metadata?.categoryId) {
      sessionStore.setAsJson<CategoryClick>(categoryClickKey, { productId: hit.productId!, metadata, properties })
    }

    return
  }

  if (type === "autocomplete") {
    // TODO: temporary returning until keyword analytics is implemented
    if ("keyword" in hit) {
      return
    }

    const metadata = metadataCache.get("search")
    if (metadata) {
      metadata.isAutoComplete = true
      return storeSearchClick(hit.productId, metadata, hit.url!, properties)
    }
  } else {
    const metadata = metadataCache.get("search")
    if (metadata) {
      metadata.isAutoComplete = false
      return storeSearchClick(hit.productId, metadata, hit.url!, properties)
    }
  }
}
