import parseUri, { ParseUriResult } from "parseuri"

export function setParameter(uri: string, key: string, value: string) {
  const base = uri.split("#")[0]
  let hash = uri.split("#")[1] || ""
  if (hash.length > 0) {
    hash = `#${hash}`
  }
  const re = new RegExp(`([?&])${key}=?.*?(&|$)`, "i")
  const separator = base.indexOf("?") !== -1 ? "&" : "?"
  if (base.match(re)) {
    return base.replace(re, `$1${key}=${value}$2`) + hash
  }
  return `${base + separator + key}=${value}${hash}`
}

export function cleanParsedUrl(parsedUrl: ParseUriResult, recommendationRefParamName: string) {
  const val = parsedUrl.queryKey[recommendationRefParamName]
  if (val === undefined) {
    return parsedUrl.href
  }
  const nostoRefParam = `${recommendationRefParamName}=${val}`
  // in the middle
  if (parsedUrl.href.indexOf(`&${nostoRefParam}`) >= 0) {
    return parsedUrl.href.replace(`&${nostoRefParam}`, "")
  }
  // first with multiple params
  if (parsedUrl.href.indexOf(`?${nostoRefParam}&`) >= 0) {
    return parsedUrl.href.replace(`?${nostoRefParam}&`, "?")
  }
  // first as only param
  if (parsedUrl.href.indexOf(`?${nostoRefParam}`) >= 0) {
    return parsedUrl.href.replace(`?${nostoRefParam}`, "")
  }
}

export function cleanUrl(url: string, recommendationRefParamName: string) {
  return cleanParsedUrl(parseUri(url), recommendationRefParamName)
}
