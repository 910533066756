import { PageType } from "./generated"
// types are generated via com.nosto.jackson.TypeScriptGeneratorTest
export * from "./generated"

export type Maybe<T> = NonNullable<T> | undefined

export type Widen<T> = T extends string ? string : T extends number ? number : T extends boolean ? boolean : T

export function maybe<T>(value?: T) {
  return value ?? undefined
}

const pageTypes: PageType[] = [
  "front",
  "category",
  "product",
  "cart",
  "search",
  "notfound",
  "order",
  "other",
  "checkout"
]

// based on com.nosto.activesession.messages.PageType
export const pageTypeAliases = {
  front: ["frente", "home"],
  category: [
    "subcategory",
    "department",
    "categoría",
    "brand",
    "brands",
    "kategorie",
    "catégorie",
    "categoria",
    "类别",
    "категория",
    "カテゴリー"
  ],
  product: [
    "produit",
    "producto",
    "artikel",
    "prodotto",
    "produkt",
    "artikel",
    "produto",
    "produtos",
    "produs",
    "продукт",
    "ürün",
    "产品",
    "製品"
  ],
  cart: ["panier", "カート"],
  search: ["検索"],
  order: ["thank-you"],
  other: ["cms", "account", "route", "default", "page", "他の"]
}

export function isPageType(val: string): val is PageType {
  return pageTypes.includes(val)
}

// type tests
export type Extends<A extends B, B> = true

export type Equals<X, Y> = (<T>() => T extends X ? 1 : 2) extends <T>() => T extends Y ? 1 : 2 ? true : false

export type Expect<T extends true> = T
