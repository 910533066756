import context from "@/core/context"
import windows from "@/core/windows"
import settings from "./settings"

export default function reportTimes(nostoReady: Date, responseReady: Date) {
  let uri = `${settings.server}/jstiming1?m=${settings.account}`

  const getParam = (param: string, val: number | Date) => {
    if (!val || val === 0) return ""
    return `&${param}=${nostoReady.getTime() - +val}`
  }
  try {
    // If browser supports html5 timing API
    if (typeof windows.site.performance !== "undefined") {
      const t = windows.site.performance.timing
      uri += getParam("fromResponseEnd", t.responseEnd)
      uri += getParam("fromResponseStart", t.responseStart)
      uri += getParam("fromDomReady", t.domContentLoadedEventEnd)
      uri += getParam("fromPageLoaded", t.loadEventEnd)
      uri += getParam("fromPreRender", responseReady)
    }
  } catch (eInner) {
    // Ignore the exception. Some platforms handle
    // performance and performance.timing access inconsistently:
    // at least Mobile Chrome on iPhone and Firefox 27.0 on Win XP.
  }
  uri += getParam("fromInclude", context.created.getTime())
  // @ts-expect-error legacy field, we don't know where this comes from
  if (context.loader && context.loader.l) {
    // @ts-expect-error legacy field, we don't know where this comes from
    const embedTs = context.loader.l
    uri += getParam("fromEmbed", embedTs.getTime())
  }

  const img = new Image()
  img.src = uri
}
