import { Event, EXTERNAL_CAMPAIGN, SOURCE } from "@/core/event"
import { currentAttribution } from "./parameterlessAttribution"
import { ParseUriResult } from "parseuri"

export function campaignEvent(currentUri: ParseUriResult) {
  const target =
    currentUri.queryKey.utm_campaign ||
    currentUri.queryKey.utm_content ||
    currentUri.queryKey.utm_term ||
    currentUri.queryKey.utm_medium ||
    currentUri.queryKey.utm_source
  if (target) {
    return { type: EXTERNAL_CAMPAIGN, target } as const
  }
}

export function isCustomCampaign(currentUri: ParseUriResult, segmentUrlParameters: string[]) {
  return segmentUrlParameters && segmentUrlParameters.filter(el => currentUri.queryKey[el])[0]
}

export function adWordEvent(currentUri: ParseUriResult) {
  if (currentUri.queryKey.gclid) {
    return { type: EXTERNAL_CAMPAIGN, target: currentUri.queryKey.gclid } as const
  }
}

export function searchQuery(currentUri: ParseUriResult, siteSearchQueryParameterName: string) {
  if (!currentUri.queryKey) {
    return null
  }
  let searchQueryValue = currentUri.queryKey[siteSearchQueryParameterName]
  if (searchQueryValue) {
    // try decoding
    try {
      searchQueryValue = decodeURIComponent(searchQueryValue)
    } catch (e1) {
      try {
        searchQueryValue = unescape(searchQueryValue)
      } catch (e2) {
        // do nothing
      }
    }
    searchQueryValue = searchQueryValue.replace(/\+/g, " ")
  }
  return searchQueryValue
}

export function sourceEvent(
  currentUri: ParseUriResult,
  nostoSourceParameterName: string,
  supportedTypes: string[],
  recommendationRefParamName: string
) {
  const sourceString = currentUri.queryKey[nostoSourceParameterName] || currentAttribution().src
  if (sourceString && (supportedTypes || []).includes(sourceString)) {
    const srcEvent: Event = { type: SOURCE, target: sourceString }
    const recRef = currentUri.queryKey[recommendationRefParamName] || currentAttribution().ref
    if (recRef) {
      srcEvent.ref = recRef
    }

    return srcEvent
  }
}

export function recommendationRef(currentUri: ParseUriResult, recommendationRefParamName: string) {
  return searchQuery(currentUri, recommendationRefParamName)
}

export function refMail(currentUri: ParseUriResult) {
  return currentUri.queryKey.refmail
}
