import { Maybe } from "@/types"
import { ParseUriResult } from "parseuri"
import { EXTERNAL_SEARCH } from "./event"

export function refererEvent(uri: ParseUriResult) {
  try {
    let keyWord: Maybe<string>
    if (uri.host.match(/google/i) || uri.host.match(/bing/i)) {
      keyWord = uri.queryKey.q
    } else if (uri.host.match(/yahoo/i)) {
      keyWord = uri.queryKey.p
    }

    if (keyWord) {
      // + don't seem to be transformed to spaces with
      return { type: EXTERNAL_SEARCH, target: keyWord.replace(/\+/g, " ") } as const
    }
  } catch (error) {
    // do nothing
  }
}
