import windows from "./windows"
import settings from "./settings"
import { parseJSON } from "@/utils/json"

interface Attribution {
  ref: string
  src?: string
  refSrc?: string
}

export type PerLinkAttributions = Record<string, Record<string, string>>

type StoredAttributions = Record<
  string,
  {
    attribution: Attribution
    ts: number
    referrer: string
  }
>

const storageKey = "nosto-attribution"
const dataRef = "data-nosto-ref"
const attributionDuration = 3 * 60 * 1000
let currentAttributionLocation: string
let currentAttributionValue: { attribution: Attribution }

function w() {
  return windows.site
}

function storage() {
  return w().localStorage
}

function location() {
  return normalizeUrl(w().location.href)
}

function normalizeUrl(url: string) {
  if (!url) {
    return url
  }
  const u = new URL(url)
  u.hash = ""
  if (settings.parameterlessAttributionNoQueryCheck) {
    u.search = ""
  }
  return u.toString()
}

function read() {
  const parsed = parseJSON<StoredAttributions>(storage().getItem(storageKey) || "{}")
  // cleanup stale attribution
  const now = Date.now()
  Object.keys(parsed)
    .filter(k => now - parsed[k].ts > attributionDuration)
    .forEach(k => delete parsed[k])
  return parsed
}

export function putAttribution(url: string, attribution: Attribution | undefined) {
  const serialized = JSON.stringify({
    ...read(),
    [normalizeUrl(url)]: attribution && {
      attribution,
      ts: Date.now(),
      referrer: location()
    }
  })
  // empty attribution should lead to removal of storage item
  if (serialized === "{}") {
    storage().removeItem(storageKey)
  } else {
    storage().setItem(storageKey, serialized)
  }
}

export function readAttribution(skipReferrer: boolean = false) {
  const url = location()
  const attribution = read()[url]
  const referrer = normalizeUrl(w().document.referrer)
  if (attribution && (skipReferrer || !referrer || attribution.referrer === referrer)) {
    currentAttributionValue = attribution
    currentAttributionLocation = url
  }
  putAttribution(url, undefined)
}

export function currentAttribution(): Attribution {
  // if location is changed without a reload it is worth reloading the attribution
  if (location() !== currentAttributionLocation) {
    // don't check for referrer as there was no page reload
    readAttribution(true)
  }
  return { ...currentAttributionValue?.attribution }
}

function saveAttribution(event: Event) {
  if (!(event.target instanceof HTMLElement)) {
    return
  }
  const link = event.target.closest<HTMLLinkElement>("a[href]")
  const targetAttributionElement = event.target.closest<HTMLLinkElement>(`[${dataRef}]`)
  if (
    !link ||
    !targetAttributionElement ||
    (targetAttributionElement.nostoAttributionCheck && !targetAttributionElement.nostoAttributionCheck(link))
  ) {
    return
  }
  const { href } = link

  // link attribution can be null as JSON.serialize([x, undefined]) is "[x, null]"
  const [campaignAttribution, linkAttributions] = parseJSON<[Attribution, PerLinkAttributions]>(
    targetAttributionElement.getAttribute(dataRef)!
  )
  putAttribution(href, { ...campaignAttribution, ...(linkAttributions || {})[href] })
}

type Check = (e: HTMLElement) => boolean

export function wrapWithAttribution(
  element: HTMLElement,
  campaignAttribution: Attribution,
  linkAttributions?: PerLinkAttributions,
  check?: Check
) {
  // non element nodes can be present here too, checking with hasAttribute
  if (element.hasAttribute && !element.hasAttribute(dataRef)) {
    if (check) {
      element.nostoAttributionCheck = check
    }
    element.addEventListener("click", saveAttribution)
    element.addEventListener("contextmenu", saveAttribution)
    element.setAttribute(dataRef, JSON.stringify([campaignAttribution, linkAttributions]))
  }
}
