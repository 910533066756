import { EventResponseMessage } from "@/types"
import overlayCreator, { Overlay } from "./overlay"
import bus from "@/core/api/bus"
import type { API } from "@/core/api"

let overlay: Overlay | undefined

export function initOverlay(api: API) {
  overlay = overlayCreator(api)
  bus.on("taggingsent", setTriggers)
}

export function activateOverlay() {
  overlay?.activate()
}

export function popupCampaigns() {
  return overlay?.campaignList().map(campaign => ({
    id: campaign.popup_id,
    name: campaign.name,
    type: campaign.type
  }))
}

export function getOverlay() {
  return overlay!
}

export function openPopup(popupId: string, options: object) {
  const error = overlay?.openPopup(popupId, options)
  if (error) {
    bus.emit("popupopened", {
      campaignId: popupId,
      error,
      type: "api"
    })
  }
}

export function enablePopup(popupId: string) {
  overlay?.enablePopup(popupId)
}

export function disablePopup(popupId: string) {
  overlay?.disablePopup(popupId)
}

export function setTriggers(responseData: EventResponseMessage) {
  overlay?.setTriggers(responseData)
}
