import axios from "axios"
import context from "@/core/context"
import windows from "@/core/windows"
import settings from "@/core/settings"
import page from "@/core/page"
import getCustomerId from "@/core/customerId"
import { wrapWithAttribution } from "@/core/parameterlessAttribution"

const productLinkSelector = "a[href*='/products/'], a[href*='#nosto_cmp']"

function noNostoAttribution(a: HTMLElement) {
  return (a.getAttribute("href") || "").indexOf(`${settings.nostoRefParam}=`) === -1
}

function findProductLinks() {
  // TODO branch logic based on platform
  return page.selectAll<HTMLLinkElement>(productLinkSelector).filter(noNostoAttribution)
}

type Callback = (e: HTMLElement) => void
type TimeoutHandle = ReturnType<typeof setTimeout>

function findProductLinksAndObserve(cb: Callback) {
  findProductLinks().forEach(cb)
  if (windows.site.MutationObserver) {
    let handle: TimeoutHandle | null = null
    const observer = new windows.site.MutationObserver(mutations => {
      if (mutations.filter(e => e.addedNodes).length) {
        if (handle) clearTimeout(handle)
        handle = setTimeout(() => findProductLinks().forEach(cb), 50)
      }
    })
    observer.observe(page.select("body")!, {
      subtree: true,
      childList: true
    })
  }
}

function cleanupHref(href: string) {
  const idx = href.indexOf("#")
  return idx > -1 ? href.substring(0, idx) : href
}

export function addCMPAttribution(resultId: string) {
  if (typeof resultId !== "string") {
    throw new Error(`Illegal argument ${resultId}`)
  }
  if (settings.parameterlessAttribution) {
    wrapWithAttribution(
      page.select("body")!,
      { src: "cmp", ref: resultId },
      undefined,
      // magento: backend attributes all links with `#nosto_cmp`, we attribute only those
      // shopify: we attribute all product links
      //
      // checks: we need to check that link is either product link or was at some point attributed with `#nosto_cmp`
      e => e.matches(productLinkSelector) && noNostoAttribution(e)
    )
  } else {
    findProductLinksAndObserve(a => {
      const suffix = new URLSearchParams()
      suffix.append(settings.sourceParameterName, "cmp")
      suffix.append(settings.nostoRefParam, resultId)
      addParamsToCleanHref(a, suffix)
    })
  }
}

function addParamsToCleanHref(a: HTMLElement, suffix: URLSearchParams) {
  const href = cleanupHref(a.getAttribute("href") || "")
  const suffixStr = suffix.toString()
  // NOTE: we cannot use proper URL constructor and URLSearchParams here because it requires
  // normalization of relative URLs, which is too much of a side effect
  a.setAttribute("href", suffixStr ? href + (href.indexOf("?") > -1 ? "&" : "?") + suffixStr : href)
}

function getDebugSettings() {
  return context.mode.getDebugState() ?? {}
}

export async function getMappings<T>(cmpMode: "magento" | "shopify" | "default") {
  const customerId = await getCustomerId()
  if (!customerId) {
    return Promise.reject(new Error("Customer not defined"))
  }
  const forcedSegments = getDebugSettings().fs || []
  let endpoint = "/cmp-mapping/metadata"
  if (cmpMode === "magento") {
    endpoint = "/cmp-mapping/magento"
  } else if (cmpMode === "shopify") {
    endpoint = "/cmp-mapping/collections"
  }
  const response = await axios.get<T>(`${settings.server}${endpoint}`, {
    params: {
      m: settings.account,
      c: customerId,
      fs: forcedSegments.join(","),
      preview: !!context.mode.isPreview()
    }
  })
  return response.data
}
