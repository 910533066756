import { Event, isEventRefType, isEventType } from "@/core/event"
import * as v from "valibot"
import { createValidateFn } from "@/utils/validator"
import { Equals, Expect, Widen } from "@/types"

type Widened<T> = { [K in keyof T]: Widen<T[K]> }

const numberToString = (v: unknown) => (typeof v === "number" ? v.toString() : v)

const EventSchema = v.object({
  type: v.string([v.custom(isEventType, "Value supplied is invalid")]),
  target: v.optional(v.coerce(v.string(), numberToString)),
  ref: v.optional(v.string()),
  refSrc: v.optional(v.string()),
  targetFragment: v.optional(v.coerce(v.string(), numberToString)),
  refType: v.optional(v.string([v.custom(isEventRefType, "Invalid refType")]))
})

export const validateEvent = createValidateFn(EventSchema)

export type tests = [
  // Event and schema input are equal
  Expect<Equals<Widened<Event>, v.Input<typeof EventSchema>>>
]
