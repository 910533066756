import simpledialog from "@nosto/simple-dialog"

export default function discountPopUp(params) {
  const actionUrl = "/overlay/discount-modal/show"
  // Context from the top-most level
  const ctx = params.context
  const siteWindow = ctx.site.window
  const nostoWindow = ctx.nosto.window
  const { api } = params
  const { settings } = params
  const { popupId } = params
  // campaignId is set on popup response, it's only used for statistics
  let campaignId = null
  const { cartSize } = params
  const { cartTotal } = params
  const { readPopupAttributes } = params
  const effectParameter = params.effect
  const { callback } = params
  const { trigger } = params
  const { forcedSegments } = params
  const writePopupAttribute = function (...args) {
    if (!params.preview) {
      params.writePopupAttribute.apply(this, args)
    }
  }
  const reqData = {
    account: settings.account,
    c: api.visit.getCustomerId(),
    fs: forcedSegments,
    popupId,
    cartSize,
    cartTotal
  }

  let couponCode = readPopupAttributes(popupId).coupon
  let dialog
  let modal

  function removeEmpty(params) {
    return Object.keys(params).reduce((result, key) => {
      const val = params[key]

      if (val !== undefined && val !== null && val !== "") {
        result[key] = val
      }

      return result
    }, {})
  }

  function toggleElementVisibility(elements) {
    elements.forEach(({ selector, value }) => {
      const element = dialog.querySelector(selector)
      if (element) {
        element.style.display = value
      }
    })
  }

  const reqDataParams = (rq) => new URLSearchParams(removeEmpty(rq)).toString()

  const validateEmail = email => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return email && re.test(email)
  }

  const isAbandonedCartPopup = trigger === "abandonedCart"

  reqData.preview = !!params.preview
  if (reqData.preview) {
    reqData.campaignId = params.campaignId
  } else {
    reqData.campaignId = readPopupAttributes(popupId).campaignId
  }

  if (ctx.popupShown) {
    // some popup is shown, skip for now
    return
  }
  ctx.popupShown = popupId

  fetch(`${settings.server}${actionUrl}?${reqDataParams(reqData)}`, {
    method: "GET",
    cache: "no-cache",
    mode: "cors",
    headers: {
      "Content-Type": "application/json"
    }
  })
  .then(res => res.json())
  .then(responseData => {
    if (responseData.neverShowInSession) {
      writePopupAttribute(popupId, "state", "closed")
      ctx.popupShown = false
      return
    }

    campaignId = responseData.campaign_id
    const campaignEffects = responseData.effect || {}
    reqData.campaignId = campaignId
    // Not popup shown as ribbon or preview
    if (campaignId && readPopupAttributes(popupId).state !== "shown" && !params.preview) {
      params.statsFn(campaignId, "triggered")
    }

    if (!responseData.html) {
      // clean cookie if present
      writePopupAttribute(popupId)
      ctx.popupShown = false
      return
    }

    // Add contents to dialog and run script in context of iframe.
    let responseHtmlNode = siteWindow.document.createElement("div")
    responseHtmlNode.innerHTML = responseData.html
    if (responseHtmlNode.childNodes.length === 1) {
      // responseData.html is wrapped into div, so we expect to get one node only.
      responseHtmlNode = responseHtmlNode.childNodes[0]
    }
    const responseDataScripts = Array.from(responseHtmlNode.querySelectorAll("script")).map(s => s.cloneNode(true)) // create nodes with script elements
    responseHtmlNode.querySelectorAll("script").forEach(s => s.remove()) // remove scripts from html we appending to main page
    siteWindow.document.body.append(responseHtmlNode) // append html/css to main page
    dialog = responseHtmlNode
    responseDataScripts.forEach(script => {
      const newScript = document.createElement("script")
      if (script.hasAttributes()) {
        Array.from(script.attributes).forEach(attr => {
          newScript.setAttribute(attr.name, attr.value)
        })
      }
      newScript.innerHTML = script.innerHTML
      nostoWindow.document.body.append(newScript) // append scripts to iframe - to execute scripts in iframe context.
    })
    // Use effects defined from api when available
    if (effectParameter && effectParameter.opacity_min) {
      campaignEffects.opacity_min = effectParameter.opacity_min
    }
    if (effectParameter && effectParameter.fadein_min) {
      campaignEffects.fadein_min = effectParameter.fadein_min
    }
    modal = simpledialog("#NostoPopUp", siteWindow, campaignEffects)

    // check response for a coupon code & update coupon cached in cookie
    if (couponCode && couponCode !== "" && responseData.data) {
      try {
        const data = JSON.parse(responseData.data)
        if (data && data.coupon && couponCode !== data.coupon) {
          writePopupAttribute(popupId, "coupon", data.coupon)
          couponCode = data.coupon
        }
      } catch (e) {
        // ignore
      }
    }

    // prepare copy-to-clipboard
    if (!siteWindow.NostoClipboard) {
      const clipboardSource = `${settings.server}/assets/public/javascripts/clipboard.min.js`
      loadScript(clipboardSource, siteWindow)
    }

    // attach handlers
    const nostoRibbonPopUp = dialog.querySelector(".NostoRibbonPopUp")
    if (nostoRibbonPopUp) {
      nostoRibbonPopUp.addEventListener("click", () => {
        dialog.querySelector("#NostoRibbon").style.display = "none"
        show()
        callback.emit("popupmaximized", {
          campaignId: popupId
        })
        // send stats
        params.statsFn(campaignId, "maximized")
      })
    }


    const nostoRibbonClose = dialog.querySelector(".NostoRibbonClose")
    const nostoOverlayClosePermanently = dialog.querySelector(".NostoOverlayClosePermanently")
    if (nostoRibbonClose) {
      nostoRibbonClose.addEventListener("click", close)
    }
    if (nostoOverlayClosePermanently) {
      nostoOverlayClosePermanently.addEventListener("click", close)
    }

    const nostoOverlayClose = dialog.querySelector(".NostoOverlayClose")
    const nostoRibbon = dialog.querySelector("#NostoRibbon")
    if (nostoOverlayClose) {
      nostoOverlayClose.addEventListener("click", () => {
        if (!nostoRibbon) {
          close()
        } else {
          hide()
        }
        callback.emit("popupminimized", {
          campaignId: popupId
        })
        params.statsFn(campaignId, "minimized")
      })
    }

    const nostoContinueBtn = dialog.querySelector("#nostoContinueBtn")
    if (nostoContinueBtn) {
      nostoContinueBtn.addEventListener("click", () => {
        if (!nostoRibbon) {
          close()
        }
        hide()
        callback.emit("popupminimized", {
          campaignId: popupId
        })
        params.statsFn(campaignId, "minimized")
      })
    }

    const nostoGoToCheckout = dialog.querySelector(".NostoGoToCheckout")
    if (nostoGoToCheckout) {
      nostoGoToCheckout.addEventListener("click", () => {
        writePopupAttribute(popupId, "checkout", true)
        writePopupAttribute(popupId, "campaignId", campaignId)
        hide()
      })
    }

    const nostoOverlaySend = dialog.querySelector("#nostoOverlaySend")
    const nostoCouponGetBtn = dialog.querySelector("#nostoCouponGetBtn")

    function handleSendCoupon(event) {
      let newsletterCheckbox
        let newsletterConsent = !isAbandonedCartPopup
        if (nostoAddress) {
          if (event.target.classList.contains("disabled")) {
            return
          }
          // disable the button to prevent multiple clicks
          event.target.classList.add("disabled")

          newsletterCheckbox = dialog.querySelector("#nostoNewsletter")
          if (newsletterCheckbox) {
            newsletterConsent = newsletterCheckbox.checked
          }
          reqData.email = nostoAddress.value
          if (!params.preview) {
            api.internal.setCustomer({
              email: nostoAddress.value,
              newsletter: newsletterConsent,
              source: "discount-popup",
              source_id: campaignId
            })
            params.statsFn(campaignId, "email")
          }
        }

        if (isAbandonedCartPopup) {
          const formElements = [
            { selector: ".NostoForm", value: "none" },
            { selector: ".NostoThankYou", value: "" }
          ]
          toggleElementVisibility(formElements)

          fetch(`${settings.server}/campaigns/sendACE?${reqDataParams(reqData)}`, {
            method: "GET",
            cache: "no-cache",
            mode: "cors",
            headers: {
              "Content-Type": "application/json"
            }
          })
          .then(res => res.json())
          .then(res => {
            const { sent } = res
            if (sent !== "true" && sent !== true) {
              // TODO: replace with logger
              throw new Error(`Failed to send email. Error:${res.message}`)
            }

            callback.emit("sendabandonedcartemail", {
              campaignId: popupId,
              email: reqData.email,
              sent,
              message: res.message
            })
          })
          .catch(error => {
            callback.emit("sendabandonedcartemail", {
              campaignId: popupId,
              email: reqData.email,
              sent: false,
              message: error
            })
            // TODO: replace with logger
            throw new Error(`Failed to send email. Error:${error}`)
          })
        }

        // fetch coupon code and show it
        
        fetch(`${settings.server}/campaigns/couponCode?${reqDataParams(reqData)}`, {
          method: "GET",
          cache: "no-cache",
          mode: "cors",
          headers: {
            "Content-Type": "application/json"
          }
        })
        .then(res => res.json())
        .then(resData => {
          if (resData && resData !== "") {
            couponCode = resData.couponCode

            // store to cookies
            writePopupAttribute(popupId, "coupon", couponCode)

            // push stats
            if (!params.preview) {
              api.internal.couponGiven(resData.campaign, resData.couponCode)
            }
            callback.emit("coupongiven", {
              campaignId: popupId,
              couponCode: resData.couponCode,
              origin: "popup"
            })
            params.statsFn(campaignId, "couponFetched")

            // update pop-up
            show()
          }
        })
        .catch(error => {
          // TODO: replace with logger?
          event.target.classList.add("disabled")

          callback.emit("coupongiven", {
            campaignId: popupId,
            error: "Failed to retrieve a coupon code.",
            origin: "popup"
          })
        })
    }

    if (nostoOverlaySend) {
      nostoOverlaySend.addEventListener("click", event => handleSendCoupon(event))
    }

    if (nostoCouponGetBtn) {
      nostoCouponGetBtn.addEventListener("click", event => handleSendCoupon(event))
    }

    const nostoAddress = dialog.querySelector("#nostoAddress")
    if (nostoAddress) {
      const nostoCouponGetBtn = dialog.querySelectorAll("#nostoOverlaySend, #nostoCouponGetBtn")
      const tagging = api.internal.extractTagging()
      if (tagging?.customer?.email) {
        nostoAddress.value = tagging.customer.email
        if (!validateEmail(tagging.customer.email)) {
            nostoCouponGetBtn.forEach(btn => btn.classList.add("disabled"))
        }
      } else {
        nostoCouponGetBtn.forEach(btn => btn.classList.add("disabled"))
      }
      ["keyup", "change", "input", "paste"].forEach(event => {
        function toggleCouponGetBtnClass() {
          const emailInput = nostoAddress.value
          nostoCouponGetBtn.forEach(btn => {
            // Value is not immediately present in the nostoAddress when paste
            // callback handler is being called. Therefore we get it a bit later
            setTimeout(() => {
              btn.classList.toggle("disabled", !validateEmail(emailInput))
            }, 1)
          })
        }
        nostoAddress.addEventListener(event, () => toggleCouponGetBtnClass())
      })
    }

    // SHOW
    // has pop-up already been shown?
    if (!isAbandonedCartPopup && readPopupAttributes(popupId).state === "shown") {
      // does the ribbon exist?
      const ribbon = dialog.querySelector("#NostoRibbon")
      if (ribbon) {
        // show ribbon instead of pop-up
        hide()
        callback.emit("popupribbonshown", {
          campaignId: popupId
        })
        return
      }
    }

    // show pop-up
    show()
    // store 'popup shown' into cookies to avoid showing pop-up after page refreshes
    writePopupAttribute(popupId, "state", "shown")
    writePopupAttribute(popupId, "campaignId", campaignId)
    callback.emit("popupopened", {
      campaignId: popupId,
      type: trigger
    })
    // update usage statistics
    params.statsFn(campaignId, "shown")
  })
  .catch(error => {
    console.error(error)
    // clean cookie if present
    writePopupAttribute(popupId)
    ctx.popupShown = false
  })

  /* Internal helper functions */
  /**
   * Shows pop-up dialog
   */
  function show() {
    const nostoRibbon = dialog.querySelector("#NostoRibbon")
    if (nostoRibbon) {
      nostoRibbon.style.display = "none"
    }
    /**
     * If couponCode exists hide button for fetching it
     */
    if (couponCode && couponCode !== "") {
      const nostoCoupon = dialog.querySelector("#nostoCoupon")
      nostoCoupon.innerHTML = couponCode
      
      const couponElements = [
        { selector: "#nostoCouponGetBtn", value: "none" },
        { selector: "#nostoOverlaySend", value: "none" },
        { selector: "#nostoAddress", value: "none" },
        { selector: "#nostoCoupon", value: "" }
      ]
      toggleElementVisibility(couponElements)

      const nostoContinueBtn = dialog.querySelector("#nostoContinueBtn")
      const nostoCouponCopyBtn = dialog.querySelector("#nostoCouponCopyBtn")
      if (!siteWindow.NostoClipboard || !dialog.querySelector("#nostoCouponCopyBtn")) {
        if (nostoCouponCopyBtn) {
          nostoCouponCopyBtn.style.display = "none"
        }
        if (nostoContinueBtn) {
          nostoContinueBtn.style.display = ""
        }
        modal.show()
      } else {
          if (nostoContinueBtn) {
            nostoContinueBtn.style.display = "none"
          }
          if (nostoCouponCopyBtn) {
            nostoCouponCopyBtn.style.display = ""
          }

          const client = new siteWindow.NostoClipboard(dialog.querySelector("#nostoCouponCopyBtn"), {
            target: () => dialog.querySelector("#nostoCoupon")
          })

          client.on("success", () => {
            if (nostoContinueBtn) {
              client.destroy()
              if (nostoContinueBtn) {
                nostoContinueBtn.style.display = ""
              }
              if (nostoCouponCopyBtn) {
                nostoCouponCopyBtn.style.display = "none"
              }
            }
              const copyAlertContent = dialog.querySelector(".NostoOverlayCopyAlertContent")
              if (copyAlertContent) {
                copyAlertContent.classList.add("shown")
                setTimeout(() => {
                copyAlertContent.classList.remove("shown")
                }, 3000);
              }
            params.statsFn(campaignId, "couponCopied")
          })
          client.on("error", () => {
            // Copy-to-clipboard failed through JS. Try to select the coupon code in JS and prompt the use to copy it.
            let input = dialog.querySelector("#nostoCouponInput")
            if (input) {
              const inputContainer = siteWindow.document.createElement("div")
              inputContainer.style.position = "relative"

              // Create a text input that will resemble the existing coupon code element.
              input = siteWindow.document.createElement("input")

              // Change the text in the success message from "Coupon was copied" to "Please copy the coupon"
              dialog.querySelectorAll(".NostoOverlayCopyAlertContent p").forEach(p => p.style.display = "none")
              const nostoSelectPrompt = dialog.querySelector(".NostoOverlayCopyAlertContent p#nostoSelectPrompt")
              if (nostoSelectPrompt) {
                nostoSelectPrompt.style.display = ""
              }
              input.setAttribute("id", "nostoCouponInput")

              // Use a text input field because that is selectable through JS
              input.setAttribute("type", "text")
              input.setAttribute("size", couponCode.length + 1)
              input.value = couponCode
              // Hide the normal input field outline & borders
              input.style.webkitAppearance = "none"
              input.style.mozAppearance = "none"
              input.style.appearance = "none"
              input.style.border = "0"
              input.style.outline = "none"
              input.style.position = "relative"
              input.classList.add("NostoInputText")

              // Add a function to the store's window to select the contents of the input element
              const elem = input
              siteWindow.nostoSelectCoupon = () => {
                // Enable the text field for the while its contents are selected
                elem.disabled = false
                elem.selectionStart = 0
                elem.selectionEnd = elem.value.length
                elem.disabled = true
              }

              // Create a div to put on top of the disabled input field to capture mouse / touch events
              const inputEventTarget = siteWindow.document.createElement("div")
              inputEventTarget.style.position = "absolute";
              inputEventTarget.style.left = "0";
              inputEventTarget.style.right = "0";
              inputEventTarget.style.top = "0";
              inputEventTarget.style.bottom = "0";

              // The events are handled as directly as possible so that "copy/cut/..." dialog will be launched
              // on iOS after the content has been selected.
              inputEventTarget.setAttribute("ontouchend", "nostoSelectCoupon()")
              inputEventTarget.setAttribute("onclick", "nostoSelectCoupon()")
              inputEventTarget.setAttribute("onmouseup", "return false;")

              dialog.querySelector("#nostoCoupon").replaceWith(inputContainer)
              inputContainer.appendChild(input)
              inputContainer.appendChild(inputEventTarget)

              if (nostoContinueBtn) {
                nostoContinueBtn.style.display = ""
              }
              if (nostoCouponCopyBtn) {
                nostoCouponCopyBtn.style.display = "none"
              }
              const copyAlertContent = dialog.querySelector(".NostoOverlayCopyAlertContent")
              if (copyAlertContent) {
                copyAlertContent.classList.add("shown")
                setTimeout(() => {
                  copyAlertContent.classList.remove("shown")
                }, 3000);
              }
            }

            // Select the text initially
            input.setSelectionRange(0, input.value.length)
            // And then disable the input field and set its opacity to be normal to not resemble disabled field as much.
            input.disabled = true
            input.style.opacity = "1.0"

            params.statsFn(campaignId, "couponCopied")
          })
        modal.show()
      }
    } else {
      const couponElements = [
        { selector: "#nostoCoupon", value: "none" },
        { selector: "#nostoCouponCopyBtn", value: "none" },
        { selector: "#nostoContinueBtn", value: "none" },
        { selector: "#nostoOverlaySend", value: "" },
        { selector: "#nostoCouponGetBtn", value: ""}
      ]
      toggleElementVisibility(couponElements)
      modal.show()
    }
  }
  /**
   * Minimizes pop-up dialog to a ribbon
   */
  function hide() {
    const ribbon = dialog.querySelector("#NostoRibbon")
    if (ribbon) {
      modal.hide()
      ribbon.style.display = ""
    } else {
      close()
    }
  }
  /**
   * Closes a dialog and makes a clean-up
   */
  function close() {
    modal.hide()
    modal.remove()
    dialog.remove()

    // cleanup cookies
    writePopupAttribute(popupId, "coupon", null)
    if (reqData.preview && reqData.preview === true) {
      // cleanup cookies
      writePopupAttribute(popupId, null)
    } else {
      // store 'popup closed' status to avoid showing pop-up\ribbon during this session
      writePopupAttribute(popupId, "state", "closed")
    }

    // send stats
    callback.emit("popupclosed", {
      campaignId: popupId
    })
    params.statsFn(campaignId, "closed")

    ctx.popupShown = false
  }
  /**
   * Loads a script by given url in a context of given window
   */
  function loadScript(url, win, callback) {
    try {
      const doc = win.document
      const head = doc.getElementsByTagName("head")[0] || doc.documentElement
      let s = doc.createElement("script")
      s.async = "async"
      s.src = url

      s.onreadystatechange = s.onload = () => {
        const state = s.readyState
        if (!state || /loaded|complete/.test(state)) {
          // Handle memory leak in IE
          s.onload = s.onreadystatechange = null
          // Remove the script
          if (s.parentNode) {
            s.parentNode.removeChild(s)
          }
          // Dereference the script
          s = undefined
          if (callback) {
            callback()
          }
        }
      }
      // use body if available. more safe in IE
      ;(doc.body || head).appendChild(s)
    } catch (e) {
      if (callback) {
        callback(`Failed to load a script: ${url}`)
      }
    }
  }

  return {
    close
  }
}
